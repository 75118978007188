.horizontal-card {
  margin: 8px;
  padding: 8px;
  text-decoration: none !important;
  h1 {
    color: $black;
  }
  a:not(.btn):not(.a-brand-link) {
    color: currentColor;
    text-decoration: none !important;

    &:focus:not(.focus-ring) {
      outline: none !important;
      text-decoration: underline;
    }
  }

  @include breakpoint("medium+") {
    width: 100%;
    display: flex;
    flex-direction: row;
    // padding-bottom: 65px;
  }
}

.horizontal-card__header {
  width: 100%;

  @include breakpoint("medium+") {
    width: 60%;
  }
}

.horizontal-card__event-date {
  font-family: Calibre, sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 1.2;
  color: #d64220;
}

.horizontal-card__image {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-card__media {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint("medium+") {
    display: block;
    height: 500px;
    width: 600px;
    object-fit: contain;
  }
}

.card__image--no-image {
  max-width: 600px;
  height: 100%;
  width: 100%;
  background: url("https://barnes-assets.s3.amazonaws.com/Member-Site/placeholder.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  color: $color__copy--medium;
  padding-bottom: 67.5%;
}

.horizontal-card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 400px;
  padding-bottom: 32px;
  @include breakpoint("medium+") {
    margin-left: 25px;
    width: 40%;
  }

  @include breakpoint("large+") {
    margin-left: none;
  }
}

.card__title {
  text-decoration: none;
  margin: 0px;

  a {
    display: inline-block;
  }
  a:not(.a-brand-link):hover {
    text-decoration: underline !important;
  }
  position: relative;

  @include breakpoint("medium+") {
    padding-top: 23px;
  }

  @include breakpoint("large+") {
    padding-top: 25px;
  }
}

.card__subtitle {
  color: $color__red--barnes;
  margin-bottom: 0;
}

/* 1 - Counteract the padding-bottom of .horizontal-card__body  */
.horizontal-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -35px;
  margin-left: -1px;
  margin-top: 20px;
  width: 100%;

  @include breakpoint("medium+") {
    flex-direction: column;
    margin-bottom: -55px;
    margin-top: 30px;
    width: 100%;
  }

  @include breakpoint("large+") {
    flex-direction: column;
    margin-bottom: -80px;
    margin-top: 40px;
    width: 100%;
  }
}

.horizontal-card-list:first-child {
  margin-top: 0;
}

.horizontal-card-list .horizontal-card {
  margin: 0 0 0 1px;
  width: 100%;
  max-width: 480px;

  @include breakpoint("medium+") {
    max-width: unset;
  }
}

.horizontal-card-list--4 {
  margin-left: 0;
  justify-content: space-between;
}

.horizontal-card-list--4 .horizontal-card {
  @include breakpoint("large+") {
    margin-left: 0;
    width: calc(25% - 20px);
  }
}

.horizontal-card--font-normal {
  & i {
    font-style: normal;
  }
}

.horizontal-card__tag {
  left: 0;
  position: absolute;
  top: 0;
}

/**
   * 2 Force the image block to be a set ratio
   * based on images ideally being 406x300
   */
.horizontal-card__media-link {
  display: block;
  height: 0; /* 2 */
  overflow: hidden;
  padding-bottom: 73.89%; /* 2 */
}

/**
   * 3 overrule the reset-link-color mixin (which I am regretting writing)
   */
.horizontal-card__media-link:focus:not(.focus-ring) {
  outline: 2px solid $color__red--barnes !important; /* 3 */
}

.horizontal-card__type {
  color: $color__copy--medium;
  margin-top: 0;
}

.horizontal-card__summary {
  p,
  span,
  div {
    font-size: 19px !important;
    line-height: (30/19) !important;

    @media print {
      font-size: 0.9rem !important;
    }
    margin-top: 12px !important;

    // Remove margin on first element (usually a <h2> or a <p2>)
    > *:first-child {
      margin-top: 0;
    }
    color: $color__copy--dark !important;
    font-family: FFMiloSerifWebText, serif !important;
    text-decoration: none !important;
  }
}
/**
   * View more button
   */
.horizontal-card-list__more {
  margin-top: 20px;

  @include breakpoint("medium+") {
    margin-top: 40px;
  }

  @include breakpoint("large+") {
    margin-top: 70px;
  }
}
