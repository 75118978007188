@import "../variables.scss";

.order-page {
  padding: 0 20px 45px 20px;

  &__text {
    max-width: 1000px;
  }

  &__header {
    margin: 0;
  }

  &__card {
    display: block;
    text-decoration: none;

    &--ticket {
      color: black;
      padding: 0 10px;
      position: relative;
      border: $button-border;

      &:first-of-type {
        margin-top: 20px;
      }
    }
  }

  &__info {
    // line-height: 12px;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;

    &--description {
      font-size: 13px;
    }

    &--name {
      font-weight: bold;
    }
  }

  &__qr {
    margin-top: 0;

    &--wide {
      width: 100%;
      border-bottom: 2px solid #e4dfdf;

      text-align: center;
    }
    // margin-left: 50%;
    // transform: translate(-50%);
  }

  &__qr-image {
    width: 105px;
    fill: $black;

    &--big {
      width: 210px;
    }
  }

  &__icon {
    height: 3em;
    position: absolute;
    top: 10px;
    right: 0;

    fill: $darkestgrey;

    &:hover {
      fill: $black;
    }
  }

  &__copy {
    font-size: 18px;

    & > p {
      margin: 10px 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    ol {
      margin: 20px 0 10px;
      padding-inline-start: 30px;
    }

    & * li {
      padding-inline-start: 10px;

      margin-top: 1em;
      margin-bottom: 1em;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &--error {
      text-align: center;
      margin: 40px 0;
      font-size: 18px;
      color: $color__copy--medium;
    }
  }

  &__order-wrapper {
    transition: opacity 1s, max-height 0.5s;
  }
}

.event-items {
  margin-bottom: 35px;

  &__back {
    font-family: Calibre, sans-serif;
    margin: 0 20px 10px;
    padding: 0;

    font-size: 18px;
    text-decoration: underline;
    color: $color__copy--light;

    border: none;
    background-color: transparent;

    display: inline-block;

    &--no-margin {
      margin: 0;
    }
  }
}

.past-orders {
  &__container {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__toggle {
    background-color: transparent;
    border: none;
    font-size: 18px;
    position: relative;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    .card {
      width: 100%;
      padding: 0;
      margin: 0;
      .ticket-card {
        padding: 16px;
        &__event-copy {
          padding: 8px;
        }
        &__event-header,
        &__event-date,
        &__event-detail {
          padding: 0 !important;
          margin: 0 !important;
          line-height: 22px;
        }
      }
    }
  }
}
