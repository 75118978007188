//////////////
/// COLORS ///
//////////////

$white: #fff;
$black: #000;
$red: #ff0000;
$orange: #d64220;
$blue: #22334a;
$darkblue: #1b2a3c;
$darkgrey: #777777;
$darkestgrey: #1c1c1c;
$grey: #dcdcdc;
$light-blue: #0058db;
$green: #10ab1a;

// Event detail variables.
$color__copy--light: #8c8c8c;
$color__copy--dark: #282828;

$color__grey--light: #f7f7f7;
$button-color: $color__grey--light;
$button-color--selected: $orange;
$button-border-color: #c6c2b9;
$color__copy--medium: #6e6e6e;

$button-border: 1px solid $button-border-color;
$border-space: 1px solid #e8e8e8;

$color__red--barnes: #d64220;
$color__red--barnes-dark: #b3371b;
$color__nav--hover: #e68e79;

// z-index map.
$z-indexes: (
  content: 1,
  modal: 10,
  footer: 10,
  alert: 11,
  banner-image: 1,
  banner-text: 2,
  title-bar: 10,
  menu-overlay: 4,
  menu: 5,
  skip: 6,
  nav: 5,
  navClose: 4,
  shopifyHeaderButton: 4,
  headerTopBar: 4,
  header: 3,
  chapterCopy: 2,
  exhibitionHeroCopy: 2,
  navNoJS: 2,
  heroCopy: 1,
  chapterOverlay: 1,
  exhibitionHeroOverlay: 1,
  tabs: 1,
  tagListDropdown: 1,
  shopifyCollectionTitle: 1,
  productGalleryThumb: 1,
  FourOhFourCopy: 1,
  accordionTabFocus: 1,
  shopifyProductListTag: 1,
  shopifyProductListImage: 0,
);

// Header heights
$headerHeights: (
  xxlarge: 130px,
  xlarge: 130px,
  large: 110px,
  medium: 100px,
  small: 70px,
  xsmall: 70px,
);

// Page padding
$pagePadding: (
  xxlarge: 110px,
  xlarge: 100px,
  large: 80px,
  medium: 50px,
  small: 40px,
  xsmall: 25px,
);

// Centennial Logo Padding
$logo100Padding: (
  xxlarge: 30px,
  xlarge: 30px,
  large: 25px,
  medium: 45px,
);
