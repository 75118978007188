@import "../variables.scss";

  .alert {
    z-index: map-get($z-indexes, "alert");
    background: $color__red--barnes;
    bottom: 0;
    color: $white;
    padding: 10px 0 3px;
    position: fixed;
    left: 0;
    width: 100%;
  
    @include breakpoint('medium+') {
      padding: 15px 0 13px;
    }
  
    @include breakpoint('large+') {
      padding-bottom: 18px;
      padding-top: 17px;
    }

    .alert__wrapper{
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

    @include breakpoint('medium+') {
        padding-left:40px;
        padding-right:40px;  }

    @include breakpoint('large+') {
        padding-left:60px;
        padding-right:60px;  }

    @include breakpoint('xlarge+') {
      padding-left: 80px;
      padding-right: 80px;
    }
      
    .alert__copy {
        padding-right: 35px;
        font-size: 18px;
        line-height: 1.75rem;

        :first-child {
          margin-top: 0;
        }
      
        a {
          color: $white;
        }
      }
    }

    .alert__btn{
      background-color: transparent;
      border: none;
  
      svg {
        color: $white;
        width: 15px;
        height: 15px;
      }
    }
  }
