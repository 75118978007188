@import "../variables.scss";

.membership {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 45px;

  &__header {
    padding: 10px 20px 0;

    font-size: 27px;
    font-weight: bold;
    margin: 0 0 10px 0;
  }

  &__entitlements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    font-size: 19px !important;
    line-height: (30/19) !important;

    @media print {
      font-size: 0.9rem !important;
    }

    // Remove margin on first element (usually a <h2> or a <p2>)
    > *:first-child {
      margin-top: 0;
    }
    color: $color__copy--dark !important;
    font-family: FFMiloSerifWebText, serif !important;
    text-decoration: none !important;
    margin-top: 10px;

    > * {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__entitlements-category {
    color: $color__red--barnes;
    font-weight: 700;
  }

  &__entitlements-text {
    margin-bottom: 1em;

    & * {
      line-height: inherit !important;
      text-decoration: none !important;
      font-style: normal !important;
      font-size: inherit !important;
      font-family: inherit !important;
    }

    br {
      display: none;
    }

    & > p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__benefits-list {
    padding-inline-start: 30px;

    li {
      padding-inline-start: 10px;

      margin-top: 1em;
      margin-bottom: 1em;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &__link {
    display: block;
    margin-top: 1em;
  }

  &__ellipsis-button-wrapper {
    width: 100%;
    text-align: center;
    margin: 20px 0 10px;
  }

  &__ellipsis-button {
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;

    background-color: $color__grey--light;
    border: 2px solid $grey;
    color: $color__copy--medium;
  }

  &__ellipsis-button-content {
    display: block;
  }
}

#google-wallet-button{
  margin-top: 1rem;
  .google-wallet-link{
    width: 250px;
  }
}

.cardholders {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
