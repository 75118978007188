.card {
  margin: 8px;
  padding: 8px;

  a:not(.btn):not(.a-brand-link) {
    color: currentColor;
    text-decoration: none !important;

    &:focus:not(.focus-ring) {
      outline: none !important;
      text-decoration: underline;
    }
  }
}

.card__guest-pass {
  margin: 8px;
  padding: 8px;

  a:not(.btn):not(.a-brand-link) {
    color: currentColor;
    text-decoration: none !important;

    &:focus:not(.focus-ring) {
      outline: none !important;
      text-decoration: underline;
    }
  }
}

.card__header {
  width: 100%;
}

.card__event-date {
  font-family: Calibre, sans-serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 1.2;
  color: #d64220;
}

.card__image {
  max-height: 350px;
  max-width: 480px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.card__media {
  display: block;
  width: 480px;
  object-fit: cover;
}

.card__image--no-image {
  max-height: 350px;
  max-width: 480px;
  height: 100%;
  width: 100%;
  background: url("https://barnes-assets.s3.amazonaws.com/Member-Site/placeholder.jpg");
  color: $color__copy--medium;
  padding-bottom: 67.5%;
}

.card__body {
  position: relative;

  @include breakpoint("medium+") {
    padding: 0 18% 61px 0;
  }

  @include breakpoint("large+") {
    padding-bottom: 80px;
  }
}

.card__title {
  text-decoration: none;
  margin: 0px;
  font-size: 25px;

  a {
    display: inline-block;
  }
  a:not(.a-brand-link):hover {
    text-decoration: underline !important;
  }
  position: relative;

  @include breakpoint("medium+") {
    padding: 0 18% 0 0;
  }

  @include breakpoint("large+") {
    padding-top: 25px;
  }
}

.card__subtitle {
  color: $color__red--barnes;
  margin-bottom: 0;
}

/* 1 - Counteract the padding-bottom of .card__body  */
.card-list,
.card-list__guest-pass {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -1px;
  margin-top: 20px;
  width: 100%;

  @include breakpoint("medium+") {
    margin-bottom: -55px;
    margin-top: 30px;
  }

  @include breakpoint("large+") {
    margin-bottom: -80px;
    margin-top: 40px;
  }

  .card-list__guest-pass__toggle {
    margin: 25px 0 10px 0;
    font-size: 18px;
    line-height: 18px;
    position: relative;
  }
}

.card-list {
  margin-bottom: -35px;
}

.card-list:first-child {
  margin-top: 0;
}

.card-list .card {
  margin: 0 0 0 1px;
  width: 100%;
  max-width: 480px;

  @include breakpoint("medium+") {
    width: calc(50% - 1px);
  }

  @include breakpoint("large+") {
    width: calc(33.33% - 1px);
  }
}

.card-list__guest-pass .card__guest-pass {
  margin: 0 0 0 1px;
  width: 100%;
  max-width: 480px;

  @include breakpoint("medium+") {
    width: calc(50% - 1px);
  }
}

.card-list--4 {
  margin-left: 0;
  justify-content: space-between;
}

.card-list--4 .card {
  @include breakpoint("large+") {
    margin-left: 0;
    width: calc(25% - 20px);
  }
}

.card--font-normal {
  & i {
    font-style: normal;
  }
}

.card__tag {
  left: 0;
  position: absolute;
  top: 0;
}

/**
   * 2 Force the image block to be a set ratio
   * based on images ideally being 406x300
   */
.card__media-link {
  display: block;
  height: 0; /* 2 */
  overflow: hidden;
  padding-bottom: 73.89%; /* 2 */
}

/**
   * 3 overrule the reset-link-color mixin (which I am regretting writing)
   */
.card__media-link:focus:not(.focus-ring) {
  outline: 2px solid $color__red--barnes !important; /* 3 */
}

.card__type {
  color: $color__copy--medium;
  margin-top: 0;
}

.card__summary {
  p,
  span,
  div {
    font-size: 19px !important;
    line-height: (30/19) !important;

    @media print {
      font-size: 0.9rem !important;
    }
    margin-top: 12px !important;

    // Remove margin on first element (usually a <h2> or a <p2>)
    > *:first-child {
      margin-top: 0;
    }
    color: $color__copy--dark !important;
    font-family: FFMiloSerifWebText, serif !important;
    text-decoration: none !important;
  }
}
/**
   * View more button
   */
.card-list__more {
  margin-top: 20px;

  @include breakpoint("medium+") {
    margin-top: 40px;
  }

  @include breakpoint("large+") {
    margin-top: 70px;
  }
}